import ApiManager from '../utils/apiManager'

export class UtilsService {
  constructor() {
    this.apiManager = new ApiManager();
  }

  async verifyRegistration(driverId, code) {
    const response = await this.apiManager.get({ url: `/auth/verify/${driverId}/${code}` });
    
    return response.status;
  }
}