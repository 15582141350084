import React from 'react';
import Main from './views/Main';
import Verify from './views/Verify' 
import DeleteAccount from './views/DeleteAccount' 

let routes = [
  {
    index: true,
    element: <Main />,
  },
  {
    path: "/verify/:driverId/:code",
    element: <Verify />,
  },
  {
    path: "/delete-account",
    element: <DeleteAccount />,
  },
];

export default routes;
