import React from "react";
import { useMediaQuery } from 'react-responsive';

function Header() {
  const helpEmail = 'help@onlogist.ru';

  const isMobile = useMediaQuery({ maxWidth: 1224 })
  const isMobilePortrait = useMediaQuery({ maxWidth: 1224, orientation: 'portrait' })

  const styleMain = {
    height: isMobilePortrait ? '150px' : isMobile && !isMobilePortrait ? '50px' : '5vw',
    position: isMobile ? 'fixed' : 'static',
    zIndex: isMobile ? '1' : '',
    backgroundColor: isMobile ? '#2F343A' : '',
    width: '100vw',
    display: 'flex',
    justifyContent: 'space-between'
  }

  const styleLogo = {
    width:  isMobilePortrait ? '360px' :'12vw',
    marginLeft: isMobilePortrait ? 0 : '1.5vw',
    marginTop: isMobilePortrait ? 0 : '10px'
  }

  return (
    <div style={styleMain}>
      <div style={isMobile ? { marginTop: isMobilePortrait ? '30px' : '2px', marginLeft: isMobilePortrait ? '250px' : '100px', transform: 'translate(-50%)' } : {}}><img src="/logo.svg" alt='OnLogist' style={styleLogo} /></div>
      {isMobile
      ? <a href={`mailto:${helpEmail}`} style={{ textDecoration: 'none', marginRight: isMobilePortrait ?  '200px' : '50px', marginTop: isMobilePortrait ? '50px' : '15px' }}>
        <p style={{
          color: '#fff',
          fontSize: isMobilePortrait ? '38px' : '16px',
          letterSpacing: '0.05vw',
        }}>{helpEmail}</p>
      </a>
      : <div style={{
        display: 'flex',
        marginRight: '2vw',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <a href={`mailto:${helpEmail}`} style={{ textDecoration: 'none' }}>
          <p style={{
            color: '#fff',
            fontSize: '0.83vw',
            letterSpacing: '0.05vw',
          }}>{helpEmail}</p>
        </a>
      </div>}
    </div>
  );
}

export default Header;

