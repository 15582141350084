import React from "react";
import { useMediaQuery } from 'react-responsive';

function DeleteAccount() {

  const isMobilePortrait = useMediaQuery({ maxWidth: 1224, orientation: 'portrait' })
  const isMobileLandscape = useMediaQuery({ maxWidth: 1224, orientation: 'landscape' })

  return (
    <div style={{ marginTop: isMobilePortrait ? '300px' : isMobileLandscape ? '100px'  : '10vw' }}>
      <div style={{ 
        width: isMobilePortrait ? '500px' : isMobileLandscape ? '200px'  :  '10vw',
        margin: 'auto',
      }}>
        <img src="/logo.svg" alt='OnLogist' style={{width: isMobilePortrait ? '500px' : isMobileLandscape ? '200px'  : '10vw'}} />
      </div>

      <div style={{ 
        textAlign: 'center',
        marginTop: '1vw' 
      }}>
          <span style={{ 
            color: '#EC951E',
            fontSize: isMobilePortrait ? 24 : 18,
            lineHeight: '35px'
          }}>Для удаления аккаунта, пожалуйста, напишите нам на почту <br />
            <a href="mailto:onlogistservice@gmail.com" style={{ color: 'white', textDecoration: 'none' }}>onlogistservice@gmail.com</a>
          </span>

      </div>
    </div>
  );
}

export default DeleteAccount;

